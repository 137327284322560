import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../context/DataContext";
import { useSelector } from "react-redux";
import { getUserData } from "../redux/slices/userSlices";
import { Wallet } from "../constants/constants";
import { decryptWallet } from "../utils/utils";
import QRCode from "qrcode.react";
import icon_fufu from "../assets/icon-fufu.png";
import img_user from "../assets/user.png";
import icon_creator from "../assets/icon-creator.svg";
import icon_mision from "../assets/icon-mision.svg";
import box_coin from "../assets/box-coin.png";
import btn_claim from "../assets/btn-claim.png";
import box_time from "../assets/box-time.png";
import box_time_1 from "../assets/box-time-1.png";
import box_coin_1 from "../assets/box-coin-1.png";

function GameHome() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const { userTele, wallet, setWallet } = useContext(DataContext)!;

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
  }, [dataUser]);

  const gotoGameMission = () => {
    navigate("/game/mission");
  };

  const gotoGameCreator = () => {
    navigate("/game/creator");
  };

  return (
    <>
      <div className="flex flex-col justify-start mx-auto w-full text-base leading-6 bg-contain bg-center bg-[url('../src/assets/game-bg.png')]">
        <div className="flex flex-col w-full mt-[20px]">
          <div className="flex gap-2 justify-center self-start py-2 pr-4 pl-3 rounded-r-[40px] shadow-sm bg-black text-white bg-opacity-90 cursor-pointer">
            <img
              loading="lazy"
              src={icon_fufu}
              className="shrink-0 w-6 aspect-square"
            />
            <div className="text-white">123456789</div>
          </div>
        </div>
        <div className="flex gap-3 justify-between w-full mt-[151px]">
          <div className="flex flex-col justify-center items-center">
            <div
              onClick={gotoGameCreator}
              className="flex gap-2 justify-center items-end py-2 pr-4 pl-3 border border-black  text-white border-solid shadow bg-black bg-opacity-90 max-w-[130px] rounded-r-[40px] cursor-pointer"
            >
              <div>Creator</div>
              <img
                loading="lazy"
                src={icon_creator}
                className="w-6 aspect-square"
              />
            </div>
          </div>
          <div className="relative">
            <img loading="lazy" src={img_user} className="w-full" />
          </div>
          <div className="flex flex-col justify-center items-center">
            <div
              onClick={gotoGameMission}
              className="flex gap-2 justify-center py-2 pr-4 pl-3 text-base font-bold leading-6 text-white whitespace-nowrap shadow bg-black bg-opacity-90 rounded-l-[40px] cursor-pointer"
            >
              <img
                loading="lazy"
                src={icon_mision}
                className="shrink-0 w-6 aspect-square"
              />
              <div>Mision</div>
            </div>
          </div>
        </div>
        <div className="w-full mt-auto mb-[25px]">
          <div className="flex flex-col items-center text-base font-bold leading-6 text-center text-white max-w-[90%] mx-auto">
            <div className="flex overflow-hidden relative flex-col gap-2 justify-center w-full text-xl whitespace-nowrap aspect-[2.86] max-w-[200px] cursor-pointer">
              <img
                loading="lazy"
                srcSet={box_coin}
                className="object-cover absolute inset-0 size-full"
              />
              <div className="flex gap-2 mx-auto justify-center self-start py-2 pr-4 pl-3 text-white z-10">
                <img
                  loading="lazy"
                  src={icon_fufu}
                  className="shrink-0 w-8 aspect-square"
                />
                <div className="text-white">123456789</div>
              </div>
            </div>
            <div className="overflow-hidden relative flex-col justify-center px-6 py-3.5 mt-4 max-w-full aspect-[4] w-[200px] cursor-pointer">
              <img
                loading="lazy"
                srcSet={btn_claim}
                className="object-cover absolute inset-0 size-full"
              />
              <div className="relative text-white z-10">Claim Now</div>
            </div>
            <div className="flex gap-5 justify-between self-stretch mt-6">
              <div className="flex flex-col items-center">
                <div className="overflow-hidden relative flex-col justify-center items-start py-3.5 pr-3 pl-14 aspect-[3.6] w-[180px]">
                  <img
                    loading="lazy"
                    srcSet={box_time}
                    className="object-cover absolute inset-0 size-full"
                  />
                  <div className="relative text-white z-10">2 Hours</div>
                </div>
                <div className="overflow-hidden relative flex-col justify-center px-14 pt-2.5 pb-2 mt-2.5 aspect-[3.6]">
                  <img
                    loading="lazy"
                    srcSet={box_time_1}
                    className="object-cover absolute inset-0 size-full"
                  />
                  <div className="relative text-white z-10">2 Hours</div>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <div className="overflow-hidden relative flex-col justify-center items-start py-3.5 pr-6 pl-12 aspect-[3.6] w-[180px]">
                  <img
                    loading="lazy"
                    srcSet={box_coin_1}
                    className="object-cover absolute inset-0 size-full"
                  />
                  <div className="relative text-white z-10">1/ Hours</div>
                </div>
                <div className="overflow-hidden relative flex-col justify-center px-14 pt-2.5 pb-2 mt-2.5 aspect-[3.6]">
                  <img
                    loading="lazy"
                    srcSet={box_time_1}
                    className="object-cover absolute inset-0 size-full"
                  />
                  <div className="relative text-white z-10">2 Hours</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GameHome;
