import { Fragment, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import NavBar from "../components/Navbar";
import ScrollToTop from "../components/ScrollTopTop";

export default function Root() {
  return (
    <>
      <div
        id="container"
        className="w-full max-w-[480px] mx-auto min-h-screen flex flex-col justify-start bg-white bg-opacity-80"
      >
        <div className="relative flex-1 flex">
          <Outlet />
        </div>
      </div>
      {/* <NavBar /> */}
    </>
  );
}
