import { formatBalance } from "../utils/utils";
import icon_arrow from "../assets/icon-arrow.svg";
import icon_sui from "../assets/icon-sui.png";
import icon_done from "../assets/icon-done.svg";
import { useNavigate } from "react-router-dom";

type Props = {
  data: any;
  isDone: boolean;
};

const ITemMissionInDetail = ({ data, isDone }: Props) => {
  const navigate = useNavigate();
  const onGotoDetail = () => {
    console.log("Goto detail");
    if (!isDone) {
      navigate("/game/mission/2");
    }
  };
  return (
    <div
      onClick={onGotoDetail}
      className="flex gap-4 justify-between p-3 mt-4 w-full text-base font-semibold leading-6 rounded-2xl border border-solid border-zinc-800 border-opacity-50 text-zinc-800"
    >
      <div className="flex gap-4">
        <div className="shrink-0 w-8 h-8 rounded-lg">
          <img
            loading="lazy"
            srcSet={icon_sui}
            className="shrink-0 aspect-square w-[60px]"
          />
        </div>
        <div className="my-auto">Invite bonus</div>
      </div>
      {isDone ? (
        <img
          loading="lazy"
          src={icon_done}
          className="shrink-0 my-auto w-6 aspect-square"
        />
      ) : (
        <img
          loading="lazy"
          src={icon_arrow}
          className="shrink-0 my-auto w-6 aspect-square"
        />
      )}
    </div>
  );
};

export default ITemMissionInDetail;
