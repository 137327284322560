import { useContext } from "react";
import DataContext from "../context/DataContext";
import Button from "./Button";
import icon_transfer_true from "../assets/icon-transfer-true.svg";
import icon_transfer_false from "../assets/icon-transfer-false.svg";
import { formatShortAddress } from "../utils/utils";
type Props = {
  result: any;
  onClose: () => void;
};

const TransferResult = ({ result, onClose }: Props) => {
  const { balanceToken } = useContext(DataContext)!;

  const onGoHome = () => {
    onClose();
  };
  return (
    <div className="flex flex-col justify-center py-20 mx-auto w-full text-base font-semibold leading-6 text-center bg-white max-w-[480px] text-zinc-800">
      <div className="flex flex-col px-3 py-4 mt-28 w-full">
        {result.status === true ? (
          <>
            <img
              loading="lazy"
              src={icon_transfer_true}
              className="self-center max-w-full aspect-square w-[120px]"
            />
            <div className="mt-4 text-2xl leading-6 text-lime-400">
              Transaction completed
            </div>
            <div className="mt-4 text-3xl leading-6">
              {result.amount} {result.tokenSymbol}
            </div>
            <div className="mt-4">has been successfully sent to</div>
          </>
        ) : (
          <>
            <img
              loading="lazy"
              src={icon_transfer_false}
              className="self-center max-w-full aspect-square w-[120px]"
            />
            <div className="mt-4 text-2xl leading-6 text-rose-600">
              Transaction Fail
            </div>
            <div className="mt-4 text-3xl leading-6">
              {result.amount} {result.tokenSymbol}
            </div>
            <div className="mt-4 leading-[137.5%]">has been Fail sent to</div>
          </>
        )}
        <div className="mt-4 leading-6 break-words">
          {formatShortAddress(result.toAddress, 8)}
        </div>
        <div className="mt-4 text-teal-500">
          <a
            target="_blank"
            href={`https://suiscan.xyz/mainnet/tx/${result.txHash}`}
          >
            View transaction details
          </a>
        </div>
        <Button
          isLoading={false}
          className=""
          label="Return to Home"
          handleClick={onGoHome}
        />
      </div>
    </div>
  );
};

export default TransferResult;
