import { useContext, useEffect } from "react";
import DataContext from "../context/DataContext";
import { getUserData } from "../redux/slices/userSlices";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BoxInputKey from "../components/BoxInputKey";
import icon_warming from "../assets/icon-warming.svg";

function Backups() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const { wallet } = useContext(DataContext)!;

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
  }, [dataUser]);
  return (
    <>
      <div className="flex flex-col p-4 mx-auto w-full text-base shadow-sm max-w-[480px] text-zinc-800">
        <div className="text-3xl font-black">Credentials</div>
        <div className="flex gap-2 px-4 py-2 mt-5 leading-6 bg-amber-300 rounded-2xl">
          <img
            loading="lazy"
            src={icon_warming}
            className="shrink-0 my-auto w-6 aspect-square"
          />
          <div className="flex-1">
            Do not share your passphrase or private key with anyone, even with
            us!
          </div>
        </div>
        <BoxInputKey
          title="Address"
          description="We have created a unique SUI address for you"
          content={wallet?.address}
          isShowDefault={true}
        />

        <BoxInputKey
          title="Private key"
          description="Copy your private key right now to avoid losing your account!"
          content={wallet?.privateKey}
          isShowDefault={false}
        />

        {wallet?.phrase ? (
          <BoxInputKey
            title="Seed phrase"
            description="Copy your seed phrase right now to avoid losing your account!"
            content={wallet?.phrase}
            isShowDefault={false}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Backups;
