import { ChangeEvent, Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/Button";
import CustomInput from "../components/Input";
import DataContext from "../context/DataContext";
import { ToastContainer, toast } from "react-toastify";
import { getUserData } from "../redux/slices/userSlices";
import { useSelector } from "react-redux";
import { Balance, Wallet } from "../constants/constants";
import { formatBalance, transferSui, transferToken } from "../utils/utils";
import icon_camera from "../assets/icon-camera.svg";
import icon_sui from "../assets/icon-sui.png";
import icon_select from "../assets/icon-select.svg";
import icon_close from "../assets/icon-close.svg";
import ChooseTokenITem from "../components/ChooseTokenITem";
import icon_swap from "../assets/icon-swap.svg";
import icon_settings from "../assets/icon-settings.svg";
import ChooseToken from "../components/ChooseToken";
import SettingSwap from "../components/SettingSwap";

import { Network, TurbosSdk } from "turbos-clmm-sdk";
import type { Trade, Contract } from "turbos-clmm-sdk";
export const sdk = new TurbosSdk(Network.mainnet);

function Swap() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const { userTele, wallet, balanceToken } = useContext(DataContext)!;
  const [isLoading, setIsLoading] = useState(false);
  const [isShowChoose, setIsShowChoose] = useState(false);
  const [isShowSettings, setIsShowSettings] = useState(false);
  const [srcSelect, setSrcSelect] = useState("A");
  const [perAmount, setPerAmount] = useState(50);
  const [balance, setBalance] = useState(0);
  const [result, setResult] = useState<any>(null);

  const [dataA, setDataA] = useState<any>({
    token: null,
    tokenBalance: null,
    amount: 0,
    balance: 0,
  });
  const [dataB, setDataB] = useState<any>({
    token: null,
    tokenBalance: null,
    amount: 0,
    balance: 0,
  });

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
    testSDK();
  }, [dataUser]);

  const testSDK = async () => {
    console.log("sdk", sdk);

    const contract = await sdk.contract.getConfig();
    console.log("contract", contract);

    const fees = await sdk.contract.getFees();
    console.log("fees", fees);

    // const swapResults = await sdk.trade.computeSwapResult({
    //   pools: Array<{
    //     pool: string;
    //     a2b: boolean;
    //   }>,
    //   address: string;
    //   amountSpecified: number | string;
    //   amountSpecifiedIsInput: boolean;
    // });
  };

  useEffect(() => {
    if (
      balanceToken &&
      balanceToken?.length > 0 &&
      dataA.tokenBalance == null
    ) {
      const blaA =
        Number.parseInt(balanceToken[0].balance.totalBalance) /
        10 ** Number(balanceToken[0].token.decimals);
      setDataA({
        ...dataA,
        tokenBalance: balanceToken[0],
        amount: (blaA * perAmount) / 100,
        balance: blaA,
        token: balanceToken[0].balance.coinType,
      });
      const blaB =
        Number.parseInt(
          balanceToken[balanceToken.length - 1].balance.totalBalance
        ) /
        10 ** Number(balanceToken[balanceToken.length - 1].token.decimals);
      setDataB({
        ...dataB,
        tokenBalance: balanceToken[balanceToken.length - 1],
        balance: blaB,
        token: balanceToken[balanceToken.length - 1].balance.coinType,
      });
    }
  }, [balanceToken]);

  const onChangeAmount = (per: number) => {
    setPerAmount(per);

    setDataA({ ...dataA, amount: (dataA.balance * per) / 100 });
  };

  const handleSwap = async () => {
    if (dataA.amount == 0) {
      toast.error("Please fill amount fields");
      return;
    } else {
      setIsLoading(true);
      //setResult(tmp);

      //console.log("result token", tmp);
      setIsLoading(false);
    }
  };

  const onClickItemToken = (data: Balance) => {
    setIsShowChoose(!isShowChoose);
    if (srcSelect === "A") {
      if (data.token.symbol != dataB.tokenBalance.token.symbol) {
        const blaA =
          Number.parseInt(data.balance.totalBalance) /
          10 ** Number(data.token.decimals);
        setDataA({
          ...dataA,
          tokenBalance: data,
          amount: (blaA * perAmount) / 100,
          balance: blaA,
          token: data.balance.coinType,
        });
      }
    } else {
      if (data.token.symbol != dataA.tokenBalance.token.symbol) {
        const blaB =
          Number.parseInt(data.balance.totalBalance) /
          10 ** Number(data.token.decimals);
        setDataB({
          ...dataB,
          tokenBalance: data,
          amount: 0,
          balance: blaB,
          token: data.balance.coinType,
        });
      }
    }
  };

  const onShowSelectToken = (src: string = "") => {
    setSrcSelect(src);
    setIsShowChoose(!isShowChoose);
  };

  const onChangeAtoB = () => {
    const tmp = dataA;
    setDataA(dataB);
    setDataB(tmp);
  };

  const onShowSettings = () => {
    setIsShowSettings(!isShowSettings);
  };

  const onCloseSettings = () => {
    setIsShowSettings(!isShowSettings);
  };

  return (
    <>
      {wallet ? (
        result ? (
          <>
            <div className="flex flex-col pb-20 mx-auto w-full text-base leading-6 ">
              <div className="flex flex-col px-3 py-4 w-full">
                <div className="text-3xl font-black text-zinc-800">
                  Transfer
                </div>
                {result.status ? (
                  <p className="text-[12px] text-[#195531] font-bold mb-[4px]">
                    TxHash:{" "}
                    <a href={`https://suiscan.xyz/mainnet/tx/${result.txHash}`}>
                      {result.txHash}
                    </a>
                  </p>
                ) : (
                  <p className="text-[12px] text-[#195531] font-bold mb-[4px]">
                    {result.message}
                  </p>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col pb-20 mx-auto w-full text-base leading-6 max-w-[480px]">
              <div className="flex flex-col px-3 py-4 w-full">
                <div className="flex gap-0 text-3xl font-black whitespace-nowrap text-zinc-800">
                  <div className="flex-1">Swap</div>
                  <img
                    onClick={onShowSettings}
                    loading="lazy"
                    src={icon_settings}
                    className="shrink-0 my-auto w-6 aspect-square"
                  />
                </div>
                <div className="text-black">Trade token in an instant</div>
                <div className="mt-4 font-black text-zinc-800">From</div>
                <div className="flex gap-2 justify-center mt-2 whitespace-nowrap">
                  <div className="flex-1 justify-center items-start p-4 rounded-2xl border border-solid shadow-sm border-zinc-800 text-zinc-800 text-opacity-60">
                    <CustomInput
                      className=""
                      type="number"
                      placeholder="Amount"
                      value={dataA.amount.toString()}
                      onChange={(e) =>
                        setDataA({
                          ...dataA,
                          amount: Number.parseFloat(e.target.value),
                        })
                      }
                    />
                  </div>
                  {dataA.tokenBalance ? (
                    <div
                      onClick={() => {
                        onShowSelectToken("A");
                      }}
                      className="flex gap-2.5 p-4 font-semibold rounded-2xl border border-solid shadow-sm border-zinc-800 text-zinc-800 cursor-pointer"
                    >
                      <img
                        loading="lazy"
                        src={
                          dataA.tokenBalance.token.iconUrl
                            ? dataA.tokenBalance.token.iconUrl
                            : icon_sui
                        }
                        className="shrink-0 w-6 aspect-square"
                      />
                      <div>{dataA.tokenBalance.token.symbol}</div>
                      <img
                        loading="lazy"
                        src={icon_select}
                        className="shrink-0 my-auto w-4 aspect-square"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="flex flex-col justify-center mt-2 text-center whitespace-nowrap rounded-2xl border border-solid shadow-sm border-zinc-800 text-zinc-800 text-opacity-60">
                  <div className="flex gap-0 justify-between">
                    <div
                      className={`flex-1 ${
                        perAmount === 25
                          ? "text-white bg-indigo-500 rounded-[100px]"
                          : "text-zinc-800 text-opacity-50"
                      }`}
                      onClick={() => {
                        onChangeAmount(25);
                      }}
                    >
                      25%
                    </div>
                    <div
                      className={`flex-1 ${
                        perAmount === 50
                          ? "text-white bg-indigo-500 rounded-[100px]"
                          : "text-zinc-800 text-opacity-50"
                      }`}
                      onClick={() => {
                        onChangeAmount(50);
                      }}
                    >
                      50%
                    </div>
                    <div
                      className={`flex-1 ${
                        perAmount === 75
                          ? "text-white bg-indigo-500 rounded-[100px]"
                          : "text-zinc-800 text-opacity-50"
                      }`}
                      onClick={() => {
                        onChangeAmount(75);
                      }}
                    >
                      75%
                    </div>
                    <div
                      className={`flex-1 ${
                        perAmount === 100
                          ? "text-white bg-indigo-500 rounded-[100px]"
                          : "text-zinc-800 text-opacity-50"
                      }`}
                      onClick={() => {
                        onChangeAmount(100);
                      }}
                    >
                      MAX
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 justify-between mt-2">
                  <div className="text-zinc-800 text-opacity-50">
                    Balance: {dataA.balance}
                  </div>
                  <div className="text-zinc-800 text-opacity-60">~0 USD</div>
                </div>
                <img
                  onClick={onChangeAtoB}
                  loading="lazy"
                  src={icon_swap}
                  className="self-center mt-4 aspect-square w-[102px]"
                />
                <div className="mt-4 font-black text-zinc-800">From</div>
                <div className="flex gap-2 justify-center mt-2 whitespace-nowrap">
                  <div className="flex-1 justify-center items-start p-4 rounded-2xl border border-solid shadow-sm border-zinc-800 text-zinc-800 text-opacity-60">
                    <CustomInput
                      className=""
                      type="number"
                      placeholder="Amount"
                      value={dataB.amount.toString()}
                      onChange={(e) =>
                        setDataB({
                          ...dataB,
                          amount: Number.parseFloat(e.target.value),
                        })
                      }
                    />
                  </div>
                  {dataB.tokenBalance ? (
                    <div
                      onClick={() => {
                        onShowSelectToken("B");
                      }}
                      className="flex gap-2.5 p-4 font-semibold rounded-2xl border border-solid shadow-sm border-zinc-800 text-zinc-800 cursor-pointer"
                    >
                      <img
                        loading="lazy"
                        src={
                          dataB.tokenBalance.token.iconUrl
                            ? dataB.tokenBalance.token.iconUrl
                            : icon_sui
                        }
                        className="shrink-0 w-6 aspect-square"
                      />
                      <div>{dataB.tokenBalance.token.symbol}</div>
                      <img
                        loading="lazy"
                        src={icon_select}
                        className="shrink-0 my-auto w-4 aspect-square"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="flex gap-2 justify-between mt-2">
                  <div className="text-zinc-800 text-opacity-50">
                    Balance: {dataB.balance}
                  </div>
                  <div className="text-zinc-800 text-opacity-60">~0 USD</div>
                </div>
                <div className="flex gap-4 justify-between mt-4 text-center leading-[137.5%] text-zinc-800">
                  <div>Price impact:</div>
                  <div>0%</div>
                </div>
                <div className="flex gap-4 justify-between mt-2 text-center leading-[137.5%] text-zinc-800">
                  <div>Minimum Received:</div>
                  <div>0 USDT</div>
                </div>
                <div className="flex gap-4 justify-between mt-2 text-center leading-[137.5%] text-zinc-800">
                  <div>Fee:</div>
                  <div>0 SUI</div>
                </div>
                <div className="flex gap-4 justify-between mt-2 text-center leading-[137.5%] text-zinc-800">
                  <div>Slippage Tolerance</div>
                  <div>0.5%</div>
                </div>
                <Button
                  isLoading={isLoading}
                  className=""
                  label="Swap"
                  handleClick={handleSwap}
                />
              </div>
              <ChooseToken
                isShowChoose={isShowChoose}
                onClickItemToken={onClickItemToken}
                onShowSelectToken={onShowSelectToken}
              />
              <SettingSwap
                isShowSettings={isShowSettings}
                onClose={onCloseSettings}
              />
            </div>
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
}

export default Swap;
