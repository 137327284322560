import { ChangeEvent } from "react";

type Props = {
  placeholder?: string;
  value: string;
  className: string;
  onChange: (value: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
};

const CustomInput = ({
  className,
  placeholder,
  value,
  onChange,
  type = "text",
}: Props) => {
  return (
    <div className="w-full">
      <input
        type={type}
        value={value}
        onChange={onChange}
        contentEditable="true"
        className={`w-full  break-all outline-none  ${className}`}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomInput;
