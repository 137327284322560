import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import "./App.css";
import Home from "./pages/Home";
import Deposit from "./pages/Deposit";
import ProtectedRouter from "./auth/ProtectedRouter";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import DataContext from "./context/DataContext";
import Transfer from "./pages/Transfer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedLogin from "./auth/ProtectedLogin";
import Login from "./pages/Login";
import { Balance, Wallet } from "./constants/constants";
import { getUserData } from "./redux/slices/userSlices";
import { useSelector } from "react-redux";
import { decryptWallet, getBalanceWallet } from "./utils/utils";
import Settings from "./pages/Settings";
import Backups from "./pages/Backups";
import InviteFriend from "./pages/InviteFriend";
import Swap from "./pages/Swap";
import GameHome from "./pages/GameHome";
import GameMission from "./pages/GameMission";
import GameMissionDetail from "./pages/GameMissionDetail";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
}

declare global {
  interface Window {
    Telegram: any; // Replace 'any' with the actual type if you know it
  }
}
const tele = window.Telegram.WebApp;

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      { element: <Navigate to="/Login" />, index: true },
      {
        path: "/Login",
        element: <ProtectedLogin props={<Login />} type={""} key={""} />,
      },
    ],
  },
  {
    path: "/game/*",
    element: <ProtectedRouter />,
    children: [
      { path: "", element: <Home /> },
      { path: "settings", element: <Settings /> },
      { path: "backups", element: <Backups /> },
      { path: "invite-friend", element: <InviteFriend /> },
      { path: "swap", element: <Swap /> },
      { path: "deposit", element: <Deposit /> },
      { path: "transfer", element: <Transfer /> },
      { path: "play", element: <GameHome /> },
      { path: "mission", element: <GameMission /> },
      { path: "mission/:id", element: <GameMissionDetail /> },
    ],
  },
]);

function App() {
  const [userTele, setUserTele] = useState(null);
  const [botConnection, setBotConnection] = useState<WebSocket | null>(null);
  const [wallet, setWallet] = useState<Wallet | null>(null);
  const [balanceToken, setBalanceToken] = useState<Balance[]>([]);
  const dataUser = useSelector(getUserData);

  useEffect(() => {
    console.log("tele", tele);
    tele.ready();

    const initData = tele.initDataUnsafe; // Use Telegram.WebApp.initData in production
    if (initData && initData.query_id) {
      setUserTele(initData);
    } else {
      // const abc: any = {
      //   query_id: "AAH6jMNNAAAAAPqMw02LAE24",
      //   user: {
      //     id: 1304661242,
      //     first_name: "Phong",
      //     last_name: "Tran",
      //     username: "nhiepphong",
      //     language_code: "en",
      //     allows_write_to_pm: true,
      //   },
      //   auth_date: "1715263464",
      //   hash: "792a2fb9119b96de386587dd6972ca4b0d4b03705a46ef81cc32d4ca48831418",
      // };
      // setUserTele(abc);
    }
  }, []);

  useEffect(() => {
    if (dataUser != null) {
      const w = decryptWallet(dataUser.wallet, dataUser.telegram.id.toString());
      console.log("wallet", w);
      if (w != null) {
        setWallet(w);
      }
    }
  }, [dataUser]);

  useEffect(() => {
    if (wallet) {
      getBalanceToken();
    }
  }, [wallet]);

  const getBalanceToken = async () => {
    if (wallet) {
      const balance: any = await getBalanceWallet(wallet.address);
      console.log("balance", balance);
      setBalanceToken(balance);
    }
  };

  const setButtonBackTele = (status: boolean, callback: any) => {
    try {
      if (tele) {
        if (status) {
          tele.BackButton.show();
          tele.onEvent("backButtonClicked", callback);
        } else {
          tele.BackButton.hide();
          tele.offClick("backButtonClicked", callback);
        }
      }
    } catch (error) {}
  };

  return (
    <DataContext.Provider
      value={{
        userTele,
        setUserTele,
        wallet,
        setWallet,
        balanceToken,
        setBalanceToken,
        setButtonBackTele,
      }}
    >
      <div id="bg-wrap">
        <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid slice">
          <defs>
            <radialGradient
              id="Gradient1"
              cx="50%"
              cy="50%"
              fx="0.441602%"
              fy="50%"
              r=".5"
            >
              <animate
                attributeName="fx"
                dur="34s"
                values="0%;3%;0%"
                repeatCount="indefinite"
              ></animate>
              <stop offset="0%" stopColor="rgba(255, 0, 255, 1)"></stop>
              <stop offset="100%" stopColor="rgba(255, 0, 255, 0)"></stop>
            </radialGradient>
            <radialGradient
              id="Gradient2"
              cx="50%"
              cy="50%"
              fx="2.68147%"
              fy="50%"
              r=".5"
            >
              <animate
                attributeName="fx"
                dur="23.5s"
                values="0%;3%;0%"
                repeatCount="indefinite"
              ></animate>
              <stop offset="0%" stopColor="rgba(255, 255, 0, 1)"></stop>
              <stop offset="100%" stopColor="rgba(255, 255, 0, 0)"></stop>
            </radialGradient>
            <radialGradient
              id="Gradient3"
              cx="50%"
              cy="50%"
              fx="0.836536%"
              fy="50%"
              r=".5"
            >
              <animate
                attributeName="fx"
                dur="21.5s"
                values="0%;3%;0%"
                repeatCount="indefinite"
              ></animate>
              <stop offset="0%" stopColor="rgba(0, 255, 255, 1)"></stop>
              <stop offset="100%" stopColor="rgba(0, 255, 255, 0)"></stop>
            </radialGradient>
            <radialGradient
              id="Gradient4"
              cx="50%"
              cy="50%"
              fx="4.56417%"
              fy="50%"
              r=".5"
            >
              <animate
                attributeName="fx"
                dur="23s"
                values="0%;5%;0%"
                repeatCount="indefinite"
              ></animate>
              <stop offset="0%" stopColor="rgba(0, 255, 0, 1)"></stop>
              <stop offset="100%" stopColor="rgba(0, 255, 0, 0)"></stop>
            </radialGradient>
            <radialGradient
              id="Gradient5"
              cx="50%"
              cy="50%"
              fx="2.65405%"
              fy="50%"
              r=".5"
            >
              <animate
                attributeName="fx"
                dur="24.5s"
                values="0%;5%;0%"
                repeatCount="indefinite"
              ></animate>
              <stop offset="0%" stopColor="rgba(0,0,255, 1)"></stop>
              <stop offset="100%" stopColor="rgba(0,0,255, 0)"></stop>
            </radialGradient>
            <radialGradient
              id="Gradient6"
              cx="50%"
              cy="50%"
              fx="0.981338%"
              fy="50%"
              r=".5"
            >
              <animate
                attributeName="fx"
                dur="25.5s"
                values="0%;5%;0%"
                repeatCount="indefinite"
              ></animate>
              <stop offset="0%" stopColor="rgba(255,0,0, 1)"></stop>
              <stop offset="100%" stopColor="rgba(255,0,0, 0)"></stop>
            </radialGradient>
          </defs>

          <rect
            x="13.744%"
            y="1.18473%"
            width="100%"
            height="100%"
            fill="url(#Gradient1)"
            transform="rotate(334.41 50 50)"
          >
            <animate
              attributeName="x"
              dur="20s"
              values="25%;0%;25%"
              repeatCount="indefinite"
            ></animate>
            <animate
              attributeName="y"
              dur="21s"
              values="0%;25%;0%"
              repeatCount="indefinite"
            ></animate>
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 50 50"
              to="360 50 50"
              dur="7s"
              repeatCount="indefinite"
            ></animateTransform>
          </rect>
          <rect
            x="-2.17916%"
            y="35.4267%"
            width="100%"
            height="100%"
            fill="url(#Gradient2)"
            transform="rotate(255.072 50 50)"
          >
            <animate
              attributeName="x"
              dur="23s"
              values="-25%;0%;-25%"
              repeatCount="indefinite"
            ></animate>
            <animate
              attributeName="y"
              dur="24s"
              values="0%;50%;0%"
              repeatCount="indefinite"
            ></animate>
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 50 50"
              to="360 50 50"
              dur="12s"
              repeatCount="indefinite"
            ></animateTransform>
          </rect>
          <rect
            x="9.00483%"
            y="14.5733%"
            width="100%"
            height="100%"
            fill="url(#Gradient3)"
            transform="rotate(139.903 50 50)"
          >
            <animate
              attributeName="x"
              dur="25s"
              values="0%;25%;0%"
              repeatCount="indefinite"
            ></animate>
            <animate
              attributeName="y"
              dur="12s"
              values="0%;25%;0%"
              repeatCount="indefinite"
            ></animate>
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="360 50 50"
              to="0 50 50"
              dur="9s"
              repeatCount="indefinite"
            ></animateTransform>
          </rect>
        </svg>
      </div>
      <RouterProvider router={router} />
    </DataContext.Provider>
  );
}

export default App;
