import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import CustomInput from "../components/Input";
import DataContext from "../context/DataContext";
import { toast } from "react-toastify";
import { getUserData } from "../redux/slices/userSlices";
import { useSelector } from "react-redux";
import { Balance, Wallet } from "../constants/constants";
import { transferSui, transferToken } from "../utils/utils";
import icon_camera from "../assets/icon-camera.svg";
import icon_sui from "../assets/icon-sui.png";
import icon_select from "../assets/icon-select.svg";
import ChooseToken from "../components/ChooseToken";
import TransferResult from "../components/TransferResult";

function Transfer() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const { userTele, wallet, balanceToken } = useContext(DataContext)!;
  const [isLoading, setIsLoading] = useState(false);
  const [isShowChoose, setIsShowChoose] = useState(false);
  const [tokenSelected, setTokenSelected] = useState<Balance | null>(null);
  const [perAmount, setPerAmount] = useState(50);
  const [balance, setBalance] = useState(0);
  const [result, setResult] = useState<any>(null);
  const [formData, setFormData] = useState({
    receiver: "",
    token: "",
    amount: 0,
  });
  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }

    const tmp = {
      status: false,
      message: "",
      txHash: "HWXLNpyAG9ujzijEiqz2UcZQp6dtUGB5Q2RkRxAQrR5z",
      tokenSymbol: "SUI",
      toAddress:
        "0x993f774875b58dcdf519fdb89ef1f23744060e43fa45735184185feafb38bc59",
      amount: 10,
    };
    setResult(tmp);
  }, [dataUser]);

  useEffect(() => {
    if (balanceToken && balanceToken?.length > 0 && tokenSelected == null) {
      setTokenSelected(balanceToken[0]);
      const bla =
        Number.parseInt(balanceToken[0].balance.totalBalance) /
        10 ** Number(balanceToken[0].token.decimals);
      setBalance(bla);
      setFormData({
        ...formData,
        amount: (bla * perAmount) / 100,
      });
      console.log("tokenSelected", balanceToken[0]);
    }
  }, [balanceToken]);

  const onChangeAmount = (per: number) => {
    setPerAmount(per);
    setFormData({ ...formData, amount: (balance * per) / 100 });
  };

  const handleTransfer = async () => {
    if (formData.receiver === "") {
      toast.error("Please fill receiver fields");
      return;
    } else if (tokenSelected === null) {
      toast.error("Please fill receiver fields");
      return;
    } else if (formData.token === "") {
      toast.error("Please fill asset fields");
      return;
    } else if (formData.amount === 0) {
      toast.error("Please fill amount fields");
      return;
    } else {
      setIsLoading(true);
      if (formData.token === "0x2::sui::SUI") {
        const tmp: any = await transferSui(
          wallet.privateKey,
          formData.receiver,
          formData.amount,
          tokenSelected.token.symbol
        );
        setResult(tmp);
        console.log("result SUi", tmp);
      } else {
        const tmp: any = await transferToken(
          wallet.privateKey,
          formData.receiver,
          formData.token,
          formData.amount,
          tokenSelected.token.symbol
        );
        setResult(tmp);

        console.log("result token", tmp);
      }
      setIsLoading(false);
    }
  };

  const onClickItemToken = (data: Balance) => {
    setIsShowChoose(!isShowChoose);
    setTokenSelected(data);
    const bla =
      Number.parseInt(data.balance.totalBalance) /
      10 ** Number(data.token.decimals);
    setBalance(bla);
    setFormData({
      ...formData,
      amount: (bla * perAmount) / 100,
    });
    console.log("tokenSelected", data);
  };

  useEffect(() => {
    if (tokenSelected) {
      setFormData({ ...formData, token: tokenSelected.balance.coinType });
    }
  }, [tokenSelected]);

  const onShowSelectToken = () => {
    setIsShowChoose(!isShowChoose);
  };

  const onGoHomeFromResult = () => {
    setResult(null);
  };

  return (
    <>
      {wallet ? (
        result ? (
          <TransferResult result={result} onClose={onGoHomeFromResult} />
        ) : (
          <>
            <div className="flex flex-col pb-20 mx-auto w-full text-base leading-6">
              <div className="flex flex-col px-3 py-4 w-full">
                <div className="text-3xl font-black text-zinc-800">
                  Transfer
                </div>
                <div className="text-black">Send token in an instant</div>
                <div className="mt-5 font-black text-zinc-800">Receiver</div>
                <div className="flex flex-col justify-center p-4 mt-2 rounded-2xl border border-solid shadow-sm border-zinc-800 text-zinc-800 text-opacity-60">
                  <div className="flex gap-2.5 justify-between">
                    <CustomInput
                      type="text"
                      className=""
                      placeholder="Enter Address"
                      value={formData.receiver}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          receiver: e.target.value,
                        })
                      }
                    />
                    <img
                      loading="lazy"
                      src={icon_camera}
                      className="shrink-0 w-6 aspect-square"
                    />
                  </div>
                </div>
                <div className="flex gap-2 justify-between mt-5">
                  <div className="font-black text-zinc-800">Amount</div>
                  <div className="text-zinc-800 text-opacity-50">
                    Balance: {balance}
                  </div>
                </div>
                <div className="flex gap-2 justify-center mt-2 whitespace-nowrap">
                  <div className="flex-1 justify-center items-start p-4 rounded-2xl border border-solid shadow-sm border-zinc-800 text-zinc-800 text-opacity-60">
                    <CustomInput
                      className=""
                      type="number"
                      placeholder="Amount"
                      value={formData.amount.toString()}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          amount: Number.parseFloat(e.target.value),
                        })
                      }
                    />
                  </div>
                  {tokenSelected ? (
                    <div
                      onClick={onShowSelectToken}
                      className="flex gap-2.5 p-4 font-semibold rounded-2xl border border-solid shadow-sm border-zinc-800 text-zinc-800 cursor-pointer"
                    >
                      <img
                        loading="lazy"
                        src={
                          tokenSelected.token.iconUrl
                            ? tokenSelected.token.iconUrl
                            : icon_sui
                        }
                        className="shrink-0 w-6 aspect-square"
                      />
                      <div>{tokenSelected.token.symbol}</div>
                      <img
                        loading="lazy"
                        src={icon_select}
                        className="shrink-0 my-auto w-4 aspect-square"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="flex flex-col justify-center mt-2 text-center whitespace-nowrap rounded-2xl border border-solid shadow-sm border-zinc-800 text-zinc-800 text-opacity-60">
                  <div className="flex gap-0 justify-between px-5">
                    <div
                      className={`flex-1 ${
                        perAmount === 25
                          ? "text-white bg-indigo-500 rounded-[100px]"
                          : "text-zinc-800 text-opacity-50"
                      }`}
                      onClick={() => {
                        onChangeAmount(25);
                      }}
                    >
                      25%
                    </div>
                    <div
                      className={`flex-1 ${
                        perAmount === 50
                          ? "text-white bg-indigo-500 rounded-[100px]"
                          : "text-zinc-800 text-opacity-50"
                      }`}
                      onClick={() => {
                        onChangeAmount(50);
                      }}
                    >
                      50%
                    </div>
                    <div
                      className={`flex-1 ${
                        perAmount === 75
                          ? "text-white bg-indigo-500 rounded-[100px]"
                          : "text-zinc-800 text-opacity-50"
                      }`}
                      onClick={() => {
                        onChangeAmount(75);
                      }}
                    >
                      75%
                    </div>
                    <div
                      className={`flex-1 ${
                        perAmount === 100
                          ? "text-white bg-indigo-500 rounded-[100px]"
                          : "text-zinc-800 text-opacity-50"
                      }`}
                      onClick={() => {
                        onChangeAmount(100);
                      }}
                    >
                      MAX
                    </div>
                  </div>
                </div>
                <Button
                  isLoading={isLoading}
                  className=""
                  label="Transfer"
                  handleClick={handleTransfer}
                />
              </div>
              <ChooseToken
                isShowChoose={isShowChoose}
                onClickItemToken={onClickItemToken}
                onShowSelectToken={onShowSelectToken}
              />
            </div>
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
}

export default Transfer;
