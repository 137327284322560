import { useEffect, useState } from "react";
import btn_copy from "../assets/btn_copy.svg";
import ToastProvider from "../hooks/useToastProvider";

type Props = {
  title: string;
  description: string;
  content: string;
  isShowDefault: boolean;
};
const BoxInputKey = ({ description, title, content, isShowDefault }: Props) => {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setIsShow(isShowDefault);
  }, []);
  const onChangeHideShow = () => {
    setIsShow(!isShow);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(content);
    ToastProvider({ type: "success", content: `Copied ${title} to clipboard` });
  };
  return (
    <>
      <div className="mt-4 font-bold leading-[137.5%]">{title}</div>
      <div className="mt-1">{description}</div>
      <div className="flex flex-col justify-center p-4 mt-1 leading-6 rounded-2xl border border-solid shadow-sm border-zinc-800">
        {isShow ? (
          <div className="break-all" onClick={onChangeHideShow}>
            {content}
          </div>
        ) : (
          <div className="blur-[5px] break-all" onClick={onChangeHideShow}>
            {content}
          </div>
        )}
        <img
          onClick={copyToClipboard}
          loading="lazy"
          src={btn_copy}
          className="self-end mt-2.5 w-6 aspect-square"
        />
      </div>
    </>
  );
};

export default BoxInputKey;
