import { useContext, useEffect } from "react";
import DataContext from "../context/DataContext";
import { useSelector } from "react-redux";
import { getUserData } from "../redux/slices/userSlices";
import { useNavigate } from "react-router-dom";
import ToastProvider from "../hooks/useToastProvider";
import { formatShortAddress } from "../utils/utils";
import icon_transfer from "../assets/icon-transfer.svg";
import icon_swap from "../assets/icon-swap.svg";
import icon_earn from "../assets/icon-earn.svg";
import ITemToken from "../components/ITemToken";
import icon_menu from "../assets/icon-menu.svg";
import icon_invite from "../assets/icon-invite.svg";
import icon_copy from "../assets/btn_copy.svg";
import icon_sui from "../assets/icon-sui-1.svg";
import icon_fufu from "../assets/icon-fufu.png";
import icon_next from "../assets/icon-next.svg";

function Home() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const { userTele, wallet, setWallet, balanceToken } =
    useContext(DataContext)!;

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }

    console.log("dataUser", dataUser);
  }, [dataUser]);

  const onTransfer = () => {
    navigate("/game/transfer");
  };
  const onSettings = () => {
    navigate("/game/settings");
  };
  const onInviteFriend = () => {
    navigate("/game/invite-friend");
  };
  const onSwap = () => {
    navigate("/game/swap");
  };

  const onGotoGame = () => {
    navigate("/game/play");
  };

  const copyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
    ToastProvider({ type: "success", content: `Copied to clipboard` });
  };

  return (
    <div className="flex flex-col p-4 mx-auto w-full text-base shadow-sm max-w-[480px] text-zinc-800">
      <div className="justify-between py-4 w-full text-base font-bold leading-6 text-center border-b border-solid border-stone-500 border-opacity-50 text-zinc-800">
        <div className="flex gap-3">
          <div className="flex flex-col w-[33%]" onClick={onSettings}>
            <img
              loading="lazy"
              src={icon_menu}
              className="shrink-0 w-6 aspect-square"
            />
          </div>
          <div className="flex flex-col w-[33%]">
            {dataUser.telegram.username}
          </div>
          <div
            className="flex flex-col w-[33%] items-end"
            onClick={onInviteFriend}
          >
            <div className="flex flex-1 gap-2.5 whitespace-nowrap">
              <img
                loading="lazy"
                src={icon_invite}
                className="shrink-0 w-6 aspect-square"
              />
              <div>Invite</div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col px-3 py-4 w-full">
        <div className="flex gap-2 self-center text-base font-medium whitespace-nowrap text-zinc-800">
          <div>{formatShortAddress(wallet ? wallet.address : "")}</div>
          <img
            loading="lazy"
            src={icon_copy}
            className="shrink-0 w-6 aspect-square"
            onClick={() => copyToClipboard(wallet.address)}
          />
        </div>
        <div className="flex gap-2 self-center mt-5 text-3xl font-bold text-center whitespace-nowrap text-zinc-800">
          <img
            loading="lazy"
            src={icon_sui}
            className="shrink-0 my-auto w-6 aspect-[0.75] fill-zinc-800"
          />
          <div>0.123456789</div>
        </div>
        <div className="justify-center px-2 mt-5">
          <div className="flex gap-4">
            <div
              className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full"
              onClick={onTransfer}
            >
              <div className="flex flex-col grow text-base leading-6 text-center whitespace-nowrap text-zinc-800">
                <img
                  loading="lazy"
                  src={icon_transfer}
                  className="self-center aspect-square w-full"
                />
                <div className="mt-2">Transfer</div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
              <div
                className="flex flex-col grow text-base leading-6 text-center whitespace-nowrap text-zinc-800"
                onClick={onSwap}
              >
                <img
                  loading="lazy"
                  src={icon_swap}
                  className="self-center aspect-square w-full"
                />
                <div className="mt-2">Swap</div>
              </div>
            </div>
            <div className="flex flex-col ml-5 w-[33%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col grow items-center text-base leading-6 text-center whitespace-nowrap text-zinc-800">
                <img
                  loading="lazy"
                  src={icon_earn}
                  className="aspect-square w-full"
                />
                <div className="mt-2">Earn</div>
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={onGotoGame}
          className="flex flex-col p-4 mt-5 w-full text-3xl leading-6 text-white rounded-2xl border border-solid border-zinc-800 shadow-[0px_8px_34px_#87a2ff33] [background:linear-gradient(274deg,rgb(99.87,115.39,255)_0%,rgb(255,179.56,206.72)_100%)]"
        >
          <div className="font-bold uppercase">Telefufu</div>
          <div className="mt-4 text-base leading-6">
            Lorem ipsum dolor sit amet consectetur.
          </div>
          <div className="flex gap-2 mt-4 font-extrabold whitespace-nowrap">
            <img
              loading="lazy"
              srcSet={icon_fufu}
              className="shrink-0 w-8 aspect-square"
            />
            <div className="my-auto">123456789</div>
          </div>
          <div className="flex gap-2.5 justify-center self-end px-3.5 py-2 mt-4 text-base font-medium leading-6 text-indigo-500 bg-white shadow-2xl rounded-[40px]">
            <div>Claim now</div>
            <img
              loading="lazy"
              src={icon_next}
              className="shrink-0 w-6 aspect-square"
            />
          </div>
        </div>
        <div className="mt-5 text-xl font-semibold leading-8 text-zinc-800">
          Token
        </div>

        {balanceToken && balanceToken.length > 0 ? (
          <>
            {balanceToken.map((item: any, index) => (
              <ITemToken data={item} key={index} />
            ))}
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Home;
