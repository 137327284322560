import { useEffect, useState } from "react";
import { ITEM_USERS_REFERRED } from "../utils/interface.global";
import icon_fufu_invite from "../assets/icon-fufu-invite.png";

interface InviteFriendItemProps {
  item: ITEM_USERS_REFERRED;
}
function InviteFriendItem({ item }: InviteFriendItemProps) {
  const [reward, setReward] = useState(0);
  const [countDown, setCountDown] = useState("00D:00:00:00");
  const [colorDownNumber, setColorDownNumber] = useState("3ABE8F");
  useEffect(() => {
    setReward(Number(item.ReferralInformation.totalReward));

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const timestamp = new Date(
        item.ReferralInformation.expiredTime
      ).getTime();
      const distance = timestamp - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const paddedDays = String(days).padStart(2, "0");

      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const paddedHours = String(hours).padStart(2, "0");

      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const paddedMinutes = String(minutes).padStart(2, "0");

      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      const paddedSeconds = String(seconds).padStart(2, "0");

      setCountDown(
        `${paddedDays}D:${paddedHours}:${paddedMinutes}:${paddedSeconds}`
      );

      let color = "3ABE8F";
      if (distance < 24 * 60 * 60 * 1000) {
        color = "DB6156";
      } else if (distance < 3 * 24 * 60 * 60 * 1000) {
        color = "F9C94D";
      }
      setColorDownNumber(color);

      if (distance < 0) {
        clearInterval(interval);
        setCountDown(`00D:00:00:00`);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      key={item.id}
      className="w-full flex justify-between gap-x-[4px] rounded-[8px] px-[12px]"
    >
      <div className="w-[25.4%] flex items-center">
        <p className="text-[2em] leading-[15px] font-normal text-start truncate">
          {item.twitterHandle}
        </p>
      </div>
      <div className="w-[26.14%] center">
        <p className="text-[2em] leading-[15px] font-normal text-center whitespace-nowrap">
          {/* {showTimeLocal(item.createdAt)} */}12-11-23 11:30
        </p>
      </div>
      <div className="w-[20.45%] flex items-center justify-end gap-x-[4px]">
        <p className="text-[2em] leading-[15px] font-normal text-center">
          {/* {formatNumber(reward)} */}99,999
        </p>
        <img
          src={icon_fufu_invite}
          className="shrink-0 w-4 aspect-square"
          alt=""
        />
      </div>
      <div className="w-[21.3%] flex items-center justify-end">
        <p
          style={{
            color: `#${colorDownNumber}`,
          }}
          className={`text-[2em] leading-[15px] font-normal  text-end`}
        >
          {countDown}
        </p>
      </div>
    </div>
  );
}

export default InviteFriendItem;
