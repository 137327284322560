type Props = {
  label: string;
  className: string;
  handleClick: () => void;
  isLoading?: boolean;
};
const Button = ({
  className,
  label,
  handleClick,
  isLoading = false,
}: Props) => {
  return (
    <>
      {isLoading ? (
        <div
          className={`justify-center items-center text-center self-stretch px-14 py-4 mt-4 font-semibold text-white shadow-2xl leading-[137.5%] rounded-[40px] shadow-[0px_8px_34px_#87a2ff33] [background:linear-gradient(75deg,rgb(99.87,115.39,255)_0%,rgb(255,179.56,206.72)_100%)] ${className}`}
        >
          Loading...
        </div>
      ) : (
        <div
          onClick={handleClick}
          className={`justify-center text-center items-center self-stretch px-14 py-4 mt-4 font-semibold text-white shadow-2xl leading-[137.5%] rounded-[40px] shadow-[0px_8px_34px_#87a2ff33] [background:linear-gradient(75deg,rgb(99.87,115.39,255)_0%,rgb(255,179.56,206.72)_100%)] ${className}`}
        >
          {label}
        </div>
      )}
    </>
  );
};

export default Button;
