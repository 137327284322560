import { useContext } from "react";
import { Balance } from "../constants/constants";
import DataContext from "../context/DataContext";
import icon_close from "../assets/icon-close.svg";
import ChooseTokenITem from "./ChooseTokenITem";
type Props = {
  isShowChoose: boolean;
  onClickItemToken: (value: Balance) => void;
  onShowSelectToken: () => void;
};

const ChooseToken = ({
  isShowChoose,
  onClickItemToken,
  onShowSelectToken,
}: Props) => {
  const { balanceToken } = useContext(DataContext)!;
  return (
    <>
      {isShowChoose ? (
        <div className="h-screen w-full bg-gray-800 bg-opacity-75 backdrop-blur-sm absolute">
          <div className="bg-white bg-opacity-90 absolute bottom-0 left-0 right-0 mx-auto p-4 flex flex-col px-4 pt-4 text-base font-semibold text-center rounded-2xl border border-solid border-zinc-800 max-w-[430px] text-zinc-800 transform transition duration-500 slide-up">
            <div className="flex gap-5 justify-between text-xl text-black">
              <div>Choose Token</div>
              <img
                onClick={onShowSelectToken}
                loading="lazy"
                src={icon_close}
                className="shrink-0 w-6 aspect-square"
              />
            </div>
            {balanceToken && balanceToken.length > 0 ? (
              <>
                {balanceToken.map((item: any, index) => (
                  <ChooseTokenITem
                    data={item}
                    key={index}
                    handleClick={onClickItemToken}
                  />
                ))}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ChooseToken;
