import CryptoJS from "crypto-js";

export const decryptTripleDES = (
  encryptedSoftpin: string,
  softpinKey: string
) => {
  return CryptoJS.AES.decrypt(encryptedSoftpin, softpinKey).toString(
    CryptoJS.enc.Utf8
  );
};

export const encryptTripleDES = (softpin: string, softpinKey: string) => {
  return CryptoJS.AES.encrypt(softpin, softpinKey).toString();
};
