import {
  Ed25519Keypair,
  Ed25519PublicKey,
} from "@mysten/sui.js/keypairs/ed25519";
import * as bip39 from "@scure/bip39";
import { wordlist } from "@scure/bip39/wordlists/english";
import { TOKEN_ADDRESS, Wallet } from "../constants/constants";
import { decryptTripleDES, encryptTripleDES } from "./signature";
import { fromHEX } from "@mysten/sui.js/utils";
import { decodeSuiPrivateKey } from "@mysten/sui.js/cryptography";
import { SuiClient } from "@mysten/sui.js/client";
import { MIST_PER_SUI } from "@mysten/sui.js/utils";
import { TransactionBlock } from "@mysten/sui.js/transactions";

const RPC_URL: string = String(process.env.REACT_APP_RPC_URL);

export const formatShortAddress = (address: string, length: number = 4) => {
  if (!address) return "";
  const start = address.slice(0, length);
  const end = address.slice(-length);
  return `${start}...${end}`;
};

export const newWallet = (seedphrase: string = "") => {
  try {
    let mn = seedphrase;
    if (seedphrase == "") {
      mn = bip39.generateMnemonic(wordlist);
    }
    let keypair = null;
    if (mn.search(" ") > 0) {
      keypair = Ed25519Keypair.deriveKeypairFromSeed(mn);
    } else {
      keypair = Ed25519Keypair.fromSecretKey(decodeSuiPrivateKey(mn).secretKey);
    }

    const publicKey = keypair.getPublicKey();
    const address = publicKey.toSuiAddress();
    const privateKey = keypair.getSecretKey();
    const phrase = mn.search(" ") > 0 ? mn : "";

    const wallet: Wallet = {
      address: address,
      privateKey: privateKey,
      phrase: phrase,
    };
    return wallet;
  } catch (error) {
    return null;
  }
};

// Convert MIST to Sui
export const formatBalance = (balance: string) => {
  console.log("balance", balance);
  console.log("balance", Number.parseInt(balance));
  console.log("MIST_PER_SUI", MIST_PER_SUI);
  return Number.parseInt(balance) / Number(MIST_PER_SUI);
};

export const getBalanceWallet = async (address: string) => {
  const client = new SuiClient({ url: RPC_URL });
  let response = [];
  const list = await client.getAllBalances({ owner: address });
  for (const item of list) {
    const type = await client.getCoinMetadata({ coinType: item.coinType });
    response.push({ balance: item, token: type });
  }
  //console.log("response", response);
  return response;
};

export const encryptWallet = (wallet: Wallet, password: string) => {
  return encryptTripleDES(String(JSON.stringify(wallet)), password);
};

export const decryptWallet = (encrypted: string, password: string) => {
  const data: Wallet = JSON.parse(decryptTripleDES(encrypted, password));

  return data;
};

export async function transferSui(
  privateKey: string,
  toAddress: string,
  amount: number,
  symbol: string
) {
  let response = {
    status: false,
    message: "",
    txHash: "",
    amount: amount,
    tokenSymbol: symbol,
    toAddress: toAddress,
  };
  try {
    const client = new SuiClient({ url: RPC_URL });

    const keypair = Ed25519Keypair.fromSecretKey(
      decodeSuiPrivateKey(privateKey).secretKey
    );

    const amount_send = (amount * Number(MIST_PER_SUI)).toString();
    const tx = new TransactionBlock();
    const [coin] = tx.splitCoins(tx.gas, [amount_send]);
    tx.transferObjects([coin], toAddress);
    const result = await client.signAndExecuteTransactionBlock({
      signer: keypair,
      transactionBlock: tx,
    });
    response.status = true;
    response.txHash = result.digest;
  } catch (error: any) {
    response.message = error.toString();
  }
  return response;
}

export async function transferToken(
  privateKey: string,
  toAddress: string,
  coinType: string,
  amount: number,
  symbol: string
) {
  let response = {
    status: false,
    message: "",
    txHash: "",
    amount: amount,
    tokenSymbol: symbol,
    toAddress: toAddress,
  };
  try {
    const client = new SuiClient({ url: RPC_URL });

    const keypair = Ed25519Keypair.fromSecretKey(
      decodeSuiPrivateKey(privateKey).secretKey
    );

    const coin_tmp = await client.getCoins({
      coinType: coinType,
      owner: keypair.getPublicKey().toSuiAddress(),
    });
    const [primaryCoinX, ...restCoinXs] = coin_tmp.data;

    const amount_send: string = (amount * Number(MIST_PER_SUI)).toString();
    const tx = new TransactionBlock();

    tx.mergeCoins(
      tx.object(primaryCoinX.coinObjectId),
      restCoinXs.map((coin) => tx.object(coin.coinObjectId))
    );

    const [coinIn] = tx.splitCoins(tx.object(primaryCoinX.coinObjectId), [
      tx.pure(amount_send),
    ]);
    tx.transferObjects([coinIn], tx.pure(toAddress, "address"));

    const result = await client.signAndExecuteTransactionBlock({
      signer: keypair,
      transactionBlock: tx,
    });
    response.status = true;
    response.txHash = result.digest;
  } catch (error: any) {
    response.message = error.toString();
  }
  return response;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function getTokenPrice(tokenID: string[]) {
  const results: number[] = [];
  let i: number;
  if (tokenID.length > 0) {
    for (i = 0; i < tokenID.length; i++) {
      results.push(0);
    }
    try {
      let posFID: number = 0;
      let tokenIDForCoingecko: string[] = [];
      for (i = 0; i < tokenID.length; i++) {
        tokenIDForCoingecko.push(tokenID[i]);
      }
      const link = `https://api.coingecko.com/api/v3/simple/price?ids=${tokenIDForCoingecko.join(
        ","
      )}&vs_currencies=usd`;
      let response: any = await fetch(link);
      response = await response.json();
      for (let i: number = 0; i < tokenIDForCoingecko.length; i++) {
        const tokenPrice = response[tokenIDForCoingecko[i]].usd ?? 0;
        results[i] = tokenPrice;
      }
      return results;
    } catch (error) {
      return results;
    }
  } else {
    return results;
  }
}
