import { createContext } from "react";
import { Balance } from "../constants/constants";

const DataContext = createContext<{
  userTele: any;
  setUserTele: React.Dispatch<React.SetStateAction<any>>;
  wallet: any;
  setWallet: React.Dispatch<React.SetStateAction<any>>;
  balanceToken: Balance[] | null;
  setBalanceToken: React.Dispatch<React.SetStateAction<any>>;
  setButtonBackTele: (status: boolean, callback: () => void) => void;
} | null>(null);

export default DataContext;
