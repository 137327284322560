import { formatBalance } from "../utils/utils";
import icon_arrow from "../assets/icon-arrow.svg";
import icon_sui from "../assets/icon-sui.png";
import { Balance } from "../constants/constants";

type Props = {
  data: any;
  handleClick: (data: Balance) => void;
};

const ChooseTokenITem = ({ data, handleClick }: Props) => {
  return (
    <div
      onClick={() => {
        handleClick(data);
      }}
      className="flex gap-2 py-2 mt-4 whitespace-nowrap border-b-0 border-solid border-zinc-800"
    >
      <img
        loading="lazy"
        src={data.token.iconUrl ? data.token.iconUrl : icon_sui}
        className="shrink-0 w-12 aspect-square"
      />
      <div className="my-auto">{data.token.symbol}</div>
    </div>
  );
};

export default ChooseTokenITem;
