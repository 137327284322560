import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import Game from "../assets/game.png";
import DataContext from "../context/DataContext";
import { useSelector } from "react-redux";
import { getUserData } from "../redux/slices/userSlices";
import { Wallet } from "../constants/constants";
import { decryptWallet } from "../utils/utils";
import QRCode from "qrcode.react";

function Deposit() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const { userTele, wallet, setWallet } = useContext(DataContext)!;

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
  }, [dataUser]);

  return (
    <>
      <div className="flex flex-col">
        <div className="w-full bg-blue-400"></div>
        <div className="my-[20px] mx-[40px]">
          {wallet ? (
            <div className="w-full">
              <section className="w-full">
                <h1 className="text-[20px] text-[#195531] font-bold mb-[4px] text-center">
                  Deposit
                </h1>
                <div data-v-c21686ff="" className="body">
                  <div className="body_address flex flex-col">
                    <span className="text-[12px] text-[#195531] font-bold mb-[4px]">
                      Address
                    </span>
                    <div className="">
                      <div className="border-[#185631] rounded-xl border-2 px-[20px] py-[20px] break-all">
                        <div className="text-[12px] text-[#195531] font-italic mb-[4px]">
                          {wallet.address}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="body_address flex flex-col mt-[20px]">
                    <div className="">
                      <div className="border-[#185631] rounded-xl border-2 px-[20px] py-[20px] break-all">
                        <QRCode
                          id="qrcode"
                          className="w-full h-auto aspect-square object-cover "
                          value={wallet.address}
                          size={290}
                          level={"H"}
                          includeMargin={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default Deposit;
