import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../context/DataContext";
import { useSelector } from "react-redux";
import { getUserData } from "../redux/slices/userSlices";
import ITemMission from "./ITemMission";

function GameMissionAvaiable() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const { userTele, wallet, setWallet } = useContext(DataContext)!;

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
  }, [dataUser]);

  return (
    <>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/3c14f45a8ec64c11da8831a229003b25fa5cfee26b49403878af65935ff0fcea?apiKey=748193bf4be94a4d8957d33888e0d244&"
        className="self-center max-w-full aspect-square w-[120px]"
      />
      <div className="self-center mt-4 text-3xl font-bold text-center">
        3/10 Gas-Free
      </div>
      <div className="mt-4 text-base text-center">
        Each TEF claim is a transaction on the SUI blockchain. We will cover
        your gas price as long as you have gas-free transactions
      </div>
      <div className="flex gap-4 justify-between mt-2.5 w-full rounded-2xl border border-solid border-zinc-800 border-opacity-50">
        <ITemMission data={dataUser} isDone={true} />
      </div>
      <div className="mt-2.5 text-xl leading-6">Daily</div>
      <div className="flex flex-col justify-center mt-2 rounded-2xl border border-solid border-zinc-800 border-opacity-50">
        <ITemMission data={dataUser} isDone={true} />
      </div>
      <div className="mt-2.5 text-xl leading-6">Community mission</div>
      <div className="flex flex-col mt-2 rounded-2xl border border-solid border-zinc-800 border-opacity-50">
        <ITemMission data={dataUser} isDone={true} />
        <ITemMission data={dataUser} isDone={true} />
        <ITemMission data={dataUser} isDone={true} />
        <ITemMission data={dataUser} isDone={true} />
      </div>
      <div className="mt-2.5 text-xl leading-6">Special</div>
      <div className="flex flex-col mt-2 rounded-2xl border border-solid border-zinc-800 border-opacity-50">
        <ITemMission data={dataUser} isDone={true} />
        <ITemMission data={dataUser} isDone={true} />
        <ITemMission data={dataUser} isDone={true} />
        <ITemMission data={dataUser} isDone={true} />
      </div>
    </>
  );
}

export default GameMissionAvaiable;
