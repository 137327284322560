import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";
import DataContext from "../context/DataContext";
import { useSelector } from "react-redux";
import { getUserData } from "../redux/slices/userSlices";
import InviteFriendItem from "../components/InviteFriendItem";

function InviteFriend() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const { wallet } = useContext(DataContext)!;
  const [currentList, setCurrentList] = useState<any>([
    {
      id: 1,
      twitterHandle: "@xidol.tech123456",
      ReferralInformation: { totalReward: 10, expiredTime: 1716718881 },
    },
    {
      id: 2,
      twitterHandle: "@xidol.tech123456",
      ReferralInformation: { totalReward: 11, expiredTime: 1716718881 },
    },
    {
      id: 3,
      twitterHandle: "@xidol.tech123456",
      ReferralInformation: { totalReward: 12, expiredTime: 1716718881 },
    },
  ]);

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
  }, [dataUser]);

  const onCopyLink = () => {};
  return (
    <>
      <div className="flex flex-col pt-4 pb-10 mx-auto w-full max-w-[480px]">
        <div className="self-center text-3xl font-black leading-10 text-zinc-800">
          Invite Friend
        </div>
        <div className="flex flex-col px-3 py-4 mt-4 w-full">
          <div className="flex gap-4 justify-center text-center leading-[150%]">
            <div className="flex flex-col flex-1">
              <div className="text-sm font-medium text-stone-500">
                Total Invites
              </div>
              <div className="self-center mt-1 text-xl font-semibold text-zinc-800">
                0
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="text-sm font-medium text-stone-500">
                Total Rewards
              </div>
              <div className="self-center mt-1 text-xl font-semibold text-zinc-800">
                0
              </div>
            </div>
          </div>
          <Button
            isLoading={false}
            className=""
            label="Copy link invite"
            handleClick={onCopyLink}
          />
          <div className="mt-5 text-sm font-medium leading-5 text-zinc-800">
            History
          </div>
          <div className="w-full flex flex-col gap-y-[8px] overflow-x-auto customScroll text-[5px] rounded-[8px] mb-[16px] py-[8px] mt-[16px] rounded-lg border border-solid border-zinc-800 text-zinc-800">
            <div className="px-[12px]">
              <div className=" w-full flex justify-between gap-x-[4px]  border-b border-solid border-stone-500 pb-[8px] ">
                <div className="w-[25.4%]">
                  <p className="text-[2em] leading-[14px] text-start">
                    Username
                  </p>
                </div>
                <div className="w-[26.14%]">
                  <p className="text-[2em] leading-[14px] text-center">Time</p>
                </div>
                <div className="w-[20.45%] ">
                  <p className="text-[2em] leading-[14px] text-center">
                    Reward
                  </p>
                </div>
                <div className="w-[21.3%] ">
                  <p className="text-[2em] leading-[14px] text-end whitespace-nowrap">
                    Time remaining
                  </p>
                </div>
              </div>
            </div>
            <div className=" w-full flex flex-col gap-y-[8px] mt-[8px] h-[calc(100vh-538px)] overflow-y-auto">
              {currentList &&
                currentList.length > 0 &&
                currentList.map((item: any, index: number) => (
                  <InviteFriendItem key={item.id} item={item} />
                ))}
            </div>
          </div>

          <div className="flex gap-1.5 justify-between self-center mt-5">
            <div className="flex gap-1.5">
              <div className="flex justify-center items-center p-2.5 border border-solid border-zinc-100 rounded-[32px]">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/ef3b7f422cf34dfa1e5f1953ea79d01948f7724bd2655809812f9cad4a18372a?apiKey=748193bf4be94a4d8957d33888e0d244&"
                  className="w-4 aspect-square"
                />
              </div>
              <div className="flex justify-center items-center p-2.5 border border-solid border-zinc-100 rounded-[32px]">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/5acd348bad7b5cd269bd4be03480ef2df9c16530b35312ec0e6c58dfffaf0600?apiKey=748193bf4be94a4d8957d33888e0d244&"
                  className="w-4 aspect-square"
                />
              </div>
              <div className="justify-center items-center p-2 mt-[4px] w-8 h-8 text-sm font-semibold text-white text-center whitespace-nowrap bg-zinc-800 rounded-[32px]">
                1
              </div>
              <div className="justify-center p-2.5 text-sm font-semibold whitespace-nowrap border border-solid border-zinc-100 rounded-[32px] text-zinc-800">
                2
              </div>
              <div className="justify-center p-2.5 text-sm font-semibold whitespace-nowrap border border-solid border-zinc-100 rounded-[32px] text-zinc-800">
                3
              </div>
            </div>
            <div className="my-auto text-sm font-semibold text-zinc-800">
              ...
            </div>
            <div className="flex gap-1.5">
              <div className="justify-center p-2.5 text-sm font-semibold whitespace-nowrap border border-solid border-zinc-100 rounded-[32px] text-zinc-800">
                10
              </div>
              <div className="flex justify-center items-center p-2.5 border border-solid border-zinc-100 rounded-[32px]">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/e7bdab18e54436248a6b17a1518822f5db7217da36a7c7b36c0950565be1a763?apiKey=748193bf4be94a4d8957d33888e0d244&"
                  className="w-4 aspect-square"
                />
              </div>
              <div className="flex justify-center items-center p-2.5 border border-solid border-zinc-100 rounded-[32px]">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/20b8b6c3eb345dfb60f6150d241f351df2818a5f0d2528b861a892fcc4c61b20?apiKey=748193bf4be94a4d8957d33888e0d244&"
                  className="w-4 aspect-square"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InviteFriend;
