import { formatBalance } from "../utils/utils";
import icon_arrow from "../assets/icon-arrow.svg";
import icon_sui from "../assets/icon-sui.png";
import icon_done from "../assets/icon-done.svg";
import { useNavigate } from "react-router-dom";

type Props = {
  data: any;
  isDone: boolean;
};

const ITemMission = ({ data, isDone }: Props) => {
  const navigate = useNavigate();
  const onGotoDetail = () => {
    console.log("Goto detail");
    if (!isDone) {
      navigate("/game/mission/2");
    }
  };
  return (
    <div
      onClick={onGotoDetail}
      className="flex gap-4 justify-between p-3 w-full border-b border-solid border-zinc-800 border-opacity-10"
    >
      <div className="flex gap-4">
        <img
          loading="lazy"
          srcSet={icon_sui}
          className="shrink-0 aspect-square w-[60px]"
        />
        <div className="my-auto">
          Invite bonus Invite bonus Invite bonus Invite bonus Invite bonus{" "}
        </div>
      </div>
      {isDone ? (
        <img
          loading="lazy"
          src={icon_done}
          className="shrink-0 my-auto w-6 aspect-square"
        />
      ) : (
        <img
          loading="lazy"
          src={icon_arrow}
          className="shrink-0 my-auto w-6 aspect-square"
        />
      )}
    </div>
  );
};

export default ITemMission;
