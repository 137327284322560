import { formatBalance } from "../utils/utils";
import icon_arrow from "../assets/icon-arrow.svg";
import icon_sui from "../assets/icon-sui.png";

type Props = {
  data: any;
};

const ITemToken = ({ data }: Props) => {
  return (
    <div className="flex gap-5 justify-between py-4 w-full text-center whitespace-nowrap">
      <div className="flex gap-2">
        <img
          loading="lazy"
          src={data.token.iconUrl ? data.token.iconUrl : icon_sui}
          className="shrink-0 w-12 aspect-square"
        />
        <div className="flex flex-col my-auto text-left">
          <div className="flex gap-1 text-base text-zinc-800">
            <div className="font-medium">
              {formatBalance(data.balance.totalBalance)}
            </div>
            <div className="font-semibold">{data.token.symbol}</div>
          </div>
          <div className="text-sm text-neutral-400">$32,185.056</div>
        </div>
      </div>
      <img
        loading="lazy"
        src={icon_arrow}
        className="shrink-0 my-auto aspect-[1.2] w-[29px]"
      />
    </div>
  );
};

export default ITemToken;
