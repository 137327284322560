import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../context/DataContext";
import { useSelector } from "react-redux";
import { getUserData } from "../redux/slices/userSlices";
import ITemMission from "./ITemMission";

function GameMissionCompleted() {
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const { userTele, wallet, setWallet } = useContext(DataContext)!;

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }
  }, [dataUser]);

  return (
    <>
      <div className="mt-4 text-zinc-800">Community mission</div>
      <div className="flex flex-col mt-2 text-sm leading-5 rounded-2xl border border-solid border-zinc-800 border-opacity-50 text-zinc-800">
        <ITemMission data={dataUser} isDone={true} />
        <ITemMission data={dataUser} isDone={false} />
        <ITemMission data={dataUser} isDone={false} />
        <ITemMission data={dataUser} isDone={false} />
      </div>
      <div className="mt-4 text-zinc-800">Special</div>
      <div className="flex flex-col mt-2 text-sm leading-5 rounded-2xl border border-solid border-zinc-800 border-opacity-50 text-zinc-800">
        <ITemMission data={dataUser} isDone={true} />
        <ITemMission data={dataUser} isDone={false} />
        <ITemMission data={dataUser} isDone={false} />
        <ITemMission data={dataUser} isDone={false} />
      </div>
    </>
  );
}

export default GameMissionCompleted;
