import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataContext from "../context/DataContext";
import { useSelector } from "react-redux";
import { getUserData } from "../redux/slices/userSlices";
import ITemMission from "../components/ITemMission";
import GameMissionAvaiable from "../components/GameMissionAvaiable";
import GameMissionCompleted from "../components/GameMissionCompleted";
import Button from "../components/Button";
import ITemMissionInDetail from "../components/ITemMissionInDetail";

function GameMissionDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dataUser = useSelector(getUserData);
  const [tab, setTab] = useState<string>("mission");
  const { userTele, wallet, setWallet } = useContext(DataContext)!;

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }

    console.log("id", id);
  }, [dataUser]);

  const handleStartWithTasks = () => {};

  return (
    <>
      <div className="flex flex-col pt-4 pb-8 mx-auto w-full">
        <div className="flex flex-col px-3 w-full">
          <div className="shrink-0 rounded-2xl bg-zinc-300">
            <img src="/tmp.png" className="w-full rounded-2xl" />
          </div>
          <div className="flex flex-col justify-center p-3 mt-4 rounded-2xl border border-solid border-neutral-500">
            <div className="flex gap-4 justify-between">
              <div className="flex flex-col my-auto">
                <div className="text-xl font-semibold leading-6 text-zinc-800">
                  100,000 TFF
                </div>
                <div className="mt-2 text-sm leading-5 text-zinc-500">
                  Reward
                </div>
              </div>
              <div className="shrink-0 rounded-lg bg-zinc-300 h-[60px] w-[60px]" />
            </div>
          </div>
          <div className="mt-4 text-xl font-semibold leading-6 text-zinc-800">
            Lorem ipsum dolor sit amet consectetur. Cursus aliquam diam ultrices
            luctus et vulputate in lobortis.
          </div>
          <div className="mt-2 text-sm leading-6 text-zinc-800">
            Lorem ipsum dolor sit amet consectetur. Pharetra amet mi erat
            hendrerit elit at porta. Sit gravida egestas volutpat tortor nec at
            porta diam. Imperdiet placerat volutpat dolor nullam nisl turpis
            viverra elementum. Quis orci vulputate vel scelerisque ornare duis.
          </div>
          <div className="mt-4 text-xl font-semibold leading-6 text-zinc-800">
            Social tasks
          </div>
          <div className="mt-2 text-sm leading-6 text-zinc-800">
            Lorem ipsum dolor sit amet consectetur. Pharetra amet mi erat
            hendrerit elit at porta. Sit gravida{" "}
          </div>
          <ITemMissionInDetail data={{}} isDone={true} />
          <ITemMissionInDetail data={{}} isDone={true} />
          <ITemMissionInDetail data={{}} isDone={true} />
          <ITemMissionInDetail data={{}} isDone={false} />
          <ITemMissionInDetail data={{}} isDone={false} />
          <ITemMissionInDetail data={{}} isDone={false} />
          <Button
            isLoading={false}
            className=""
            label="Start with tasks"
            handleClick={handleStartWithTasks}
          />
        </div>
      </div>
    </>
  );
}

export default GameMissionDetail;
