import { useContext, useEffect, useState } from "react";
import DataContext from "../context/DataContext";
import { getUserData, updateData } from "../redux/slices/userSlices";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../components/Button";
import ToastProvider from "../hooks/useToastProvider";
import { decryptWallet, encryptWallet, newWallet } from "../utils/utils";
import logo from "../assets/logo.png";
import CustomTextarea from "../components/Textarea";
import BoxInputKey from "../components/BoxInputKey";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dataUser = useSelector(getUserData);
  const [page, setPage] = useState("");
  const { userTele, wallet, setWallet, setButtonBackTele } =
    useContext(DataContext)!;
  const [txtPrivateKey, setTxtPrivateKey] = useState("");

  useEffect(() => {
    if (dataUser) {
      navigate("/");
    }
  }, [dataUser]);

  useEffect(() => {
    if (page == "") {
      setButtonBackTele(false, onCallBackButtonBack);
    } else {
      setButtonBackTele(true, onCallBackButtonBack);
    }
  }, [page]);

  const onLogin = () => {
    setPage("login");
  };

  const onCallBackButtonBack = () => {
    setPage("");
  };

  const onLoginWallet = () => {
    if (txtPrivateKey != "") {
      const w = newWallet(txtPrivateKey);
      if (w != null) {
        setWallet(w);
      } else {
        ToastProvider({
          type: "error",
          content: "Invalid private key or seedphrase",
        });
      }
    } else {
      ToastProvider({
        type: "error",
        content: "Please fill private key or seedphrase",
      });
    }
  };

  const onRegister = () => {
    setPage("register");

    const w = newWallet();
    if (w != null) {
      setWallet(w);
      console.log("address", w.address);
      console.log("private", w.privateKey);
      console.log("phrase", w.phrase);
    }
  };

  const onCreate = () => {
    if (wallet) {
      const dt = encryptWallet(wallet, userTele.user.id.toString());
      const w = decryptWallet(dt, userTele.user.id.toString());

      const data = { wallet: dt, telegram: userTele.user };
      dispatch(updateData(data as any));

      if (page === "register") {
        ToastProvider({ type: "success", content: "Create Wallet success" });
      } else {
        ToastProvider({ type: "success", content: "Login success" });
      }
    } else {
      ToastProvider({ type: "error", content: "Create Wallet failed" });
    }
  };

  return (
    <>
      {page === "register" ? (
        <>
          <div className="flex flex-col p-4 mx-auto w-full text-base shadow-sm max-w-[480px] text-zinc-800 bg-white bg-opacity-80">
            <div className="text-3xl font-black">Create account</div>
            <BoxInputKey
              title="Address"
              description="We have created a unique SUI address for you"
              content={wallet?.address}
              isShowDefault={true}
            />

            <BoxInputKey
              title="Private key"
              description="Copy your private key right now to avoid losing your account!"
              content={wallet?.privateKey}
              isShowDefault={false}
            />

            <BoxInputKey
              title="Seed phrase"
              description="Copy your seed phrase right now to avoid losing your account!"
              content={wallet?.phrase}
              isShowDefault={false}
            />

            <Button
              isLoading={isLoading}
              className=""
              label="Create"
              handleClick={onCreate}
            />
          </div>
        </>
      ) : page === "login" ? (
        <>
          {wallet == null ? (
            <>
              <div className="flex flex-col p-4 mx-auto w-full text-base max-w-[480px] text-zinc-800 bg-white bg-opacity-80">
                <div className="text-3xl font-black">
                  Recover using seed phrase
                </div>
                <div className="mt-4">
                  Enter the backup passphrase associated with the account.
                </div>
                <CustomTextarea
                  className="shrink-0 mt-4 rounded-2xl border border-solid border-zinc-800 h-[166px]"
                  type="text"
                  placeholder="Private key or Seedphrase"
                  value={txtPrivateKey}
                  onChange={(e) => setTxtPrivateKey(e.target.value)}
                />
                <Button
                  isLoading={isLoading}
                  className=""
                  label="Continue"
                  handleClick={onLoginWallet}
                />
              </div>
            </>
          ) : (
            <div className="flex flex-col p-4 mx-auto w-full text-base shadow-sm max-w-[480px] text-zinc-800">
              <div className="text-3xl font-black">Login account</div>
              <BoxInputKey
                title="Address"
                description="We have created a unique SUI address for you"
                content={wallet?.address}
                isShowDefault={true}
              />

              <BoxInputKey
                title="Private key"
                description="Copy your private key right now to avoid losing your account!"
                content={wallet?.privateKey}
                isShowDefault={false}
              />

              {wallet?.phrase && (
                <BoxInputKey
                  title="Seed phrase"
                  description="Copy your seed phrase right now to avoid losing your account!"
                  content={wallet?.phrase}
                  isShowDefault={false}
                />
              )}
              <Button
                isLoading={isLoading}
                className=""
                label="Done"
                handleClick={onCreate}
              />
            </div>
          )}
        </>
      ) : (
        <>
          <div className="flex flex-col justify-center items-center px-4 py-20 mx-auto w-full text-base text-center max-w-[480px] bg-white bg-opacity-80">
            <img
              loading="lazy"
              src={logo}
              className="w-full max-w-sm aspect-square"
            />
            <div className="flex flex-col justify-center self-stretch px-4 w-full">
              <div className="self-center text-3xl font-black">
                TELEFUFU Wallet
              </div>
              <div className="text-base">
                Next generation Telegram wallet. SECURE, FAST and over the SUI
                Blockchain
              </div>
            </div>
            <div className="mt-16 text-base">
              <span className="text-zinc-800">You’re being referred by </span>
              <span className="font-semibold text-zinc-800">telefufu.com</span>
            </div>
            <Button
              isLoading={isLoading}
              className=""
              label="Create new account"
              handleClick={onRegister}
            />

            <div className="mt-2 text-base text-indigo-500">
              <span className="text-zinc-800">
                I have already have a wallet.{" "}
              </span>
              <span className="font-bold text-indigo-500" onClick={onLogin}>
                Login
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Login;
