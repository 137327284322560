import { useContext, useState } from "react";
import { Balance } from "../constants/constants";
import DataContext from "../context/DataContext";
import icon_close from "../assets/icon-close.svg";
import ChooseTokenITem from "./ChooseTokenITem";
import { on } from "process";
type Props = {
  isShowSettings: boolean;
  onClose: () => void;
};

const SettingSwap = ({ isShowSettings, onClose }: Props) => {
  const [perAmount, setPerAmount] = useState(0.5);

  const onSave = () => {
    onClose();
  };
  const onCancel = () => {
    onClose();
  };

  const onChangeAmount = (per: number) => {
    setPerAmount(per);
  };

  return (
    <>
      {isShowSettings ? (
        <div className="h-screen w-full backdrop-blur-sm absolute">
          <div className="bg-white bg-opacity-90  absolute bottom-0 left-0 right-0 mx-auto p-4 flex flex-col px-4 pt-4 text-base font-semibold text-center rounded-2xl border border-solid border-zinc-800 max-w-[430px] text-zinc-800 transform transition duration-500 slide-up">
            <div className="self-center text-xl text-zinc-800">
              SWAP & LIQUIDITY
            </div>
            <div className="self-center text-zinc-800">Slippage Tolerance</div>
            <div className="flex flex-col justify-center mt-4 text-center whitespace-nowrap rounded-2xl border border-solid shadow-sm border-zinc-800">
              <div className="flex gap-0 justify-between">
                <div
                  className={`flex-1 ${
                    perAmount === 0.1
                      ? "text-white bg-indigo-500 rounded-[100px]"
                      : "text-zinc-800 text-opacity-50"
                  }`}
                  onClick={() => {
                    onChangeAmount(0.1);
                  }}
                >
                  0.1%
                </div>
                <div
                  className={`flex-1 ${
                    perAmount === 0.5
                      ? "text-white bg-indigo-500 rounded-[100px]"
                      : "text-zinc-800 text-opacity-50"
                  }`}
                  onClick={() => {
                    onChangeAmount(0.5);
                  }}
                >
                  0.5%
                </div>
                <div
                  className={`flex-1 ${
                    perAmount === 1
                      ? "text-white bg-indigo-500 rounded-[100px]"
                      : "text-zinc-800 text-opacity-50"
                  }`}
                  onClick={() => {
                    onChangeAmount(1);
                  }}
                >
                  1%
                </div>
              </div>
            </div>
            <div className="flex gap-4 justify-center mt-4 text-center whitespace-nowrap leading-[137.5%]">
              <div
                onClick={onCancel}
                className="flex-1 justify-center items-center px-14 py-4 border border-solid shadow-2xl border-zinc-800 rounded-[40px] text-zinc-800"
              >
                Cancel
              </div>
              <div
                onClick={onSave}
                className="flex-1 justify-center items-center px-14 py-4 text-white shadow-2xl rounded-[40px] shadow-[0px_8px_34px_#87a2ff33] [background:linear-gradient(75deg,rgb(99.87,115.39,255)_0%,rgb(255,179.56,206.72)_100%)]"
              >
                OK
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SettingSwap;
