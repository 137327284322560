import { useContext, useEffect } from "react";
import DataContext from "../context/DataContext";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, logout } from "../redux/slices/userSlices";
import { useNavigate } from "react-router-dom";
import ToastProvider from "../hooks/useToastProvider";
import icon_seed from "../assets/icon-seed.svg";
import icon_narrow_seed from "../assets/icon-narrow-seed.svg";
import icon_logout from "../assets/icon-logout.svg";
import { log } from "console";

function Settings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dataUser = useSelector(getUserData);
  const { userTele, wallet, setWallet, balanceToken, setBalanceToken } =
    useContext(DataContext)!;

  useEffect(() => {
    if (!dataUser) {
      navigate("/");
    }

    console.log("dataUser", dataUser);
  }, [dataUser]);

  const onTransfer = () => {
    navigate("/game/transfer");
  };
  const copyToClipboard = (content: string) => {
    navigator.clipboard.writeText(content);
    ToastProvider({ type: "success", content: `Copied to clipboard` });
  };
  const onLogout = () => {
    setWallet(null);
    setBalanceToken([]);
    dispatch(logout());
  };
  const onShowPrivateKey = () => {
    navigate("/game/backups");
  };
  return (
    <div className="flex flex-col p-4 mx-auto w-full text-base shadow-sm max-w-[480px] text-zinc-800">
      <div className="flex flex-col px-3 py-4 w-full">
        <div className="text-3xl font-black leading-10">Setting</div>
        <div
          className="flex gap-2.5 justify-between p-4 mt-5 w-full rounded-2xl border border-solid border-zinc-800"
          onClick={onShowPrivateKey}
        >
          <div className="flex gap-1">
            <img
              loading="lazy"
              src={icon_seed}
              className="shrink-0 w-6 aspect-square"
            />
            <div>Seed Phrase</div>
          </div>
          <img
            loading="lazy"
            src={icon_narrow_seed}
            className="shrink-0 aspect-[1.2] w-[29px]"
          />
        </div>
        <div
          onClick={onLogout}
          className="flex justify-center items-center px-14 py-4 mt-96 text-center border border-black border-solid shadow-2xl rounded-[40px]"
        >
          <div className="flex gap-2.5">
            <img
              loading="lazy"
              src={icon_logout}
              className="shrink-0 w-6 aspect-square"
            />
            <div>Log Out</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
